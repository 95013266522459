<template>
  <div ref="fieldGroupPrompt" class="notification is-group" :class="collapse ? 'collapsing' : ''" v-if="alerts.group && alerts.group.location && nextGroupActive">
    <button class="delete" @click="dismissAlert('nextGroupActive')"></button>
    <div v-if="!alerts.group.online" v-html="
            $t('notifications.next_group', {
                time: timeDisplay(time, timeFormat),
                label: alerts.group.label,
                location: alerts.group.location
            })"></div>
    <div v-if="alerts.group.online" v-html="
            $t('notifications.next_group_online', {
                time: timeDisplay(time, timeFormat),
                label: alerts.group.label,
                url: alerts.group.location
            })"></div>
    <div class="mt-3 display-flex justify-content-end" style="margin-right:-25px">
      <a :href="alerts.group.location" target="_blank" class="button btn-sm btn-success">{{ $t('labels.button.join') }}</a>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, onMounted } from 'vue'
import { store } from '@/store'
import { timeDisplay } from '@/helpers'
import { Sec } from '@/mixins/sec'

const collapse = ref(false)
const height = ref(null)
const fieldGroupPrompt = ref(null)

onMounted(() => {
  height.value = fieldGroupPrompt.value ? fieldGroupPrompt.value.clientHeight : 0
})

const alerts = computed(() => store.state.dashboard.alerts)
const nextGroupActive = computed(() => store.state.dashboard.nextGroupActive)
const time = computed(() => new Sec(alerts.value.group.meeting_date).time)
const timeFormat = computed(() => store.state.settings.cong.time_format)

const dismissAlert = (alert) => {
  collapse.value = true
  fieldGroupPrompt.value.style.height = height.value + 'px'
  setTimeout(() => {
    fieldGroupPrompt.value.style.height = 0
    fieldGroupPrompt.value.style.padding = 0
    fieldGroupPrompt.value.style.margin = 0
    setTimeout(() => {
      collapse.value = false
      store.dispatch('HIDE_ALERT', { alert: alert })
    }, 300)
  }, 500)
}

</script>
