<template>

    <section class="meeting-part">

      <card v-if="!event || (event && !event.has_speaker) || part !== 'talk.talk'" :time="'range'" :start="startTime" :end="endTime" :minutes="config.time"
            :color="color" :reference="config.ref || config.notes" :loading="timeThroughPart" class="mb-3"
            :actionSlot="edit"
      >
        <template v-slot:header><span v-html="config.title"></span></template>
        <template v-slot:badges v-if="this.staticParts.indexOf(part) < 0 && slots[0] !== 'static'">
          <div>
            <small class="inline-block w-16" v-if="secondSchool">1st Class</small>
            <badge class="mr-1 cursor-pointer"
                  :color="hallOneAssignee ? color : 'gray-300'"
                  v-html="hallOneAssigneeTag"
                  @click="assign('assignee', 1)">
            </badge>
            <badge v-if="slots[1]" class="cursor-pointer"
                  :color="hallOneHelper ? color + '-400' : 'gray-200'"
                  v-html="hallOneHelperTag"
                  @click="assign('helper', 1)">
            </badge>
          </div>
          <div v-if="secondSchool" class="mt-1">
            <small class="inline-block w-16">2nd Class</small>
            <badge class="mr-1 cursor-pointer"
                  :color="hallTwoAssignee ? color : 'gray-300'"
                  v-html="hallTwoAssigneeTag"
                  @click="assign('assignee', 2)">
            </badge>
            <badge v-if="slots[1]" class="cursor-pointer"
                  :color="hallTwoHelper ? color + '-400' : 'gray-200'"
                  v-html="hallTwoHelperTag"
                  @click="assign('helper', 1)">
            </badge>
          </div>
          <div v-if="thirdSchool" class="mt-1">
            <small class="inline-block w-16">3rd Class</small>
            <badge class="mr-1 cursor-pointer"
                  :color="assignee && assignee[3] && assignee[3].id ? color : 'gray-300'"
                  v-html="namedTag(assignee,3,slots[0])"
                  @click="$emit('click', ['assignee', 3])">
            </badge>
            <badge v-if="slots[1]" class="cursor-pointer"
                  :color="helper && helper[3] && helper[3].id ? color + '-400' : 'gray-200'"
                  v-html="namedTag(helper,3,slots[1])"
                  @click="$emit('click', ['helper', 3])">
            </badge>
          </div>
        </template>
        <template v-slot:actions>
          <div v-if="edit">
            <action :primary="true" :small="true" @click="insertPart" class="mr-2">
              <span class="mr-1">Insert</span>
              <fa-icon :icon="['fas', 'level-down']" />
            </action>
            <action :primary="true" :small="true" @click="editPart">Edit</action>
          </div>
        </template>
      </card>

      <card v-if="event && event.has_speaker && part === 'talk.talk'" :time="'range'" :start="startTime" :end="endTime"
            :minutes="config.time" :color="color" :reference="event.public_theme" :loading="timeThroughPart" class="mb-3">
        <template v-slot:header><span v-html="config.title"></span></template>
        <template v-slot:badges>
          <badge class="mr-1" :color="color" v-html="event.full_name"></badge>
        </template>
      </card>

      <modal :header="'Edit Meeting Part'" :active.sync="editPartActive" @submit="submitPart()">
        <input-text :label="'Title'" :type="'text'" v-model="resource.title" />
        <input-text :label="'Minutes'" :type="'number'" v-model="resource.time" />
        <input-text :label="'References'" :type="'test'" v-model="resource.ref" />
      </modal>

      <modal :header="'Assign'" @submit="submit" :active.sync="assignmentFormActive">
        <meeting-assignment :part="config" :resource="assignmentResource" v-if="assignmentFormActive" />
      </modal>

    </section>

</template>

<script>
import { mapState } from 'vuex'
import submit from 'tuxedo-api-interface/src/router/submit'
import helpers from '../../../helpers'

export default {
  name: 'MeetingPart',
  props: ['config', 'part', 'assignments', 'second', 'third', 'event', 'color', 'edit', 'activeMeeting'],
  data () {
    return {
      assignmentFormActive: false,
      staticParts: [
        'talk.song',
        'watchtower.mid-song',
        'watchtower.last-song',
        'prelude.comments',
        'living.mid-song',
        'living.review'
      ],
      now: new Date(),
      editPartActive: false,
      resource: {
        title: null,
        time: 0,
        ref: null,
        part: null
      },
      assignmentResource: {
        user_id: null,
        helper_id: null,
        hall: null,
        type: 'assignee'
      },
      hallOneAssignee: null,
      hallOneHelper: null,
      hallTwoAssignee: null,
      hallTwoHelper: null
    }
  },
  created () {
    setInterval(() => {
      this.now = new Date()
    }, 1000)
    this.setAssignees()
  },
  methods: {
    assign (type, hall) {
      this.clearData(this.assignmentResource)
      if (hall === 1) this.copyData(this.firstAssignment, this.assignmentResource)
      if (hall === 2) this.copyData(this.secondAssignment, this.assignmentResource)
      this.assignmentResource.type = type
      this.assignmentResource.hall = hall
      this.assignmentFormActive = true
    },
    submit () {
      let id = null
      let collection = 'meetings.' + this.config.meeting_id
      collection += '.parts.' + this.config.id
      collection += '.assignments'
      if (this.assignmentResource.hall === 1 && this.firstAssignment.id) {
        collection += '.' + this.firstAssignment.id
        id = this.firstAssignment.id
      }
      if (this.assignmentResource.hall === 2 && this.secondAssignment.id) {
        collection += '.' + this.secondAssignment.id
        id = this.secondAssignment.id
      }
      this.$store.dispatch(id ? 'update' : 'store', {
        collection: collection, resource: this.assignmentResource
      }).then((res) => {
        this.setAssignees()
        this.assignmentFormActive = false
      })
    },
    setAssignees () {
      const hallOneAssignment = this._.find(this.assignments, a => a.hall === 1)
      this.hallOneAssignee = hallOneAssignment ? hallOneAssignment.user_id : null
      this.hallOneHelper = hallOneAssignment ? hallOneAssignment.helper_id : null
      const hallTwoAssignment = this._.find(this.assignments, a => a.hall === 2)
      this.hallTwoAssignee = hallTwoAssignment ? hallTwoAssignment.user_id : null
      this.hallTwoHelper = hallTwoAssignment ? hallTwoAssignment.helper_id : null
    },
    namedTag (assignee, index, slot) {
      if (!assignee || !assignee[index]) return slot
      else assignee = assignee[index]
      if (this.part) {
        const parts = this.part.split('.')
        if (this.part === 'living.study') {
          if (slot === 'Reader') return assignee && assignee.id ? 'R: ' + assignee.name : slot
        }
        if (parts[0] === 'ministry') {
          if (slot === 'Assistant') return assignee && assignee.id ? 'Hh: ' + assignee.name : slot
        }
      }
      return assignee && assignee.id ? assignee.name : slot
    },
    editPart () {
      this.resource.title = this.config.title
      this.resource.time = this.config.time
      this.resource.ref = this.config.ref
      this.resource.part = this.part
      this.editPartActive = true
    },
    insertPart () {
      this.resource.title = ''
      this.resource.time = ''
      this.resource.ref = ''
      this.resource.part = this.part
      this.editPartActive = true
    },
    submitPart () {
      submit.post('/api/meeting-schedule/' + this.activeMeeting, this.resource).then((res) => {
        this.$store.commit('UPDATE_SCHEDULE', res.data.data)
        this.editPartActive = false
      })
    }
  },
  computed: {
    ...mapState({
      users: state => state.core.pubs
    }),
    hallOneAssigneeTag () {
      if (this.hallOneAssignee) return helpers.find(this.users, 'id', this.hallOneAssignee, 'full_name')
      return 'Assignee'
    },
    hallOneHelperTag () {
      let tag = 'Assistant'
      if (this.hallOneHelper) tag = helpers.find(this.users, 'id', this.hallOneHelper, 'full_name')
      if (this.config.meeting === 'living' && this.config.part === 'study') tag = 'R: ' + tag
      if (this.config.meeting === 'ministry') tag = 'Hh: ' + tag
      return tag
    },
    hallTwoAssigneeTag () {
      if (this.hallTwoAssignee) return helpers.find(this.users, 'id', this.hallTwoAssignee, 'full_name')
      return 'Assignee'
    },
    hallTwoHelperTag () {
      let tag = 'Assistant'
      if (this.hallTwoHelper) tag = helpers.find(this.users, 'id', this.hallTwoHelper, 'full_name')
      if (this.config.meeting === 'living' && this.config.part === 'study') tag = 'R: ' + tag
      if (this.config.meeting === 'ministry') tag = 'Hh: ' + tag
      return tag
    },
    firstAssignment () {
      return this.assignments.find(a => a.hall === 1) || {}
    },
    secondAssignment () {
      return this.assignments.find(a => a.hall === 2) || {}
    },
    startTime () {
      if (!this.config.startTime) return new Date()
      else return this.config.startTime
    },
    endTime () {
      const seconds = (parseInt(this.config.time) * 60)
      return new Date(this.startTime.getTime() + (seconds * 1000))
    },
    slots () {
      if (this.part) {
        const parts = this.part.split('.')
        if (this.part === 'treasures.reading') return ['Student']
        if (parts[0] === 'ministry') {
          if (this.config.title.includes('Video')) return ['static']
          if (this.config.title.includes('Apply Yourself')) return ['static']
          if (this.config.title.includes('Talk')) return ['Student']
          if (this.config.title.includes('Discussion')) return ['Assignee']
          return ['Student', 'Assistant']
        }
        if (this.part === 'prelude.comments') return ['Chairman']
        if (this.part === 'living.study') return ['Conductor', 'Reader']
        if (this.part === 'watchtower.discussion') return ['Conductor', 'Reader']
      }
      return ['Assignee']
    },
    secondSchool () {
      if (this.part && this.second) {
        if (this.part === 'treasures.reading') return true
        if (this.part === 'ministry.first') return true
        if (this.part === 'ministry.second') return true
        if (this.part === 'ministry.third') return true
        if (this.part === 'ministry.fourth') return true
        if (this.part === 'ministry.talk') return true
        if (this.part === 'ministry.study') return true
      }
      return false
    },
    thirdSchool () {
      if (this.part && this.third) {
        if (this.part === 'treasures.reading') return true
        if (this.part === 'ministry.first') return true
        if (this.part === 'ministry.second') return true
        if (this.part === 'ministry.third') return true
        if (this.part === 'ministry.fourth') return true
        if (this.part === 'ministry.talk') return true
        if (this.part === 'ministry.study') return true
      }
      return false
    },
    timeThroughPart () {
      if (this.startTime) {
        if (this.now.getTime() > this.startTime.getTime() && this.now.getTime() < this.endTime.getTime()) {
          const percentageComplete = (this.now.getTime() - this.startTime.getTime()) / (this.endTime.getTime() - this.startTime.getTime()) * 100
          return (Math.round(percentageComplete * 100) / 100)
        }
      }
      return 0
    }
  }
}
</script>
