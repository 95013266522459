import axios from 'axios'
import { get } from '@/composables/serverInterface.js'
import { assign } from '@/composables/dataInterface.js'

export const LOAD_APP_DATA = ({ dispatch, commit, getters, state }, payload) => {
  const promises = []

  promises.push(new Promise((resolve, reject) => {
    get('profile').then(response => {
      assign('profile', response)
      commit('UPDATE_LOAD_REQUESTS')
      commit('UPDATE_LOAD_REQUESTS')
      console.log('User Profile Loaded')
      resolve(response)
    })
      .catch(error => {
        console.log('Error loading events: ' + error)
        resolve(error)
      })
  }))
  promises.push(new Promise((resolve, reject) => {
    get(['cart-locations']).then(response => {
      console.log('Cart Locations Loaded')
      commit('UPDATE_LOAD_REQUESTS')
      commit('UPDATE_LOAD_REQUESTS')
      resolve(response)
    }).catch(error => {
      resolve(error)
    })
  }))
  promises.push(new Promise((resolve, reject) => {
    dispatch('GET_SLIM_MAPS').then(response => {
      commit('UPDATE_LOAD_REQUESTS')
      commit('UPDATE_LOAD_REQUESTS')
      console.log('Maps Loaded')
      resolve(response)
    }, error => {
      console.log('Error loading maps: ' + error)
      resolve(error)
    })
  }))
  promises.push(new Promise((resolve, reject) => {
    dispatch('GET_FIELD_MEETINGS', { token: payload.token }).then(response => {
      commit('UPDATE_LOAD_REQUESTS')
      commit('UPDATE_LOAD_REQUESTS')
      console.log('Field Service Meetings')
      resolve(response)
    }, error => {
      console.log('Error loading field service meetings: ' + error)
      resolve(error)
    })
  }))
  promises.push(new Promise((resolve, reject) => {
    get('events').then(response => {
      assign('events', response)
      commit('UPDATE_LOAD_REQUESTS')
      commit('UPDATE_LOAD_REQUESTS')
      console.log('Congregation Events Loaded')
      resolve(response)
    }, error => {
      console.log('Error loading events: ' + error)
      resolve(error)
    })
  }))
  promises.push(new Promise((resolve, reject) => {
    get(['streets'])
      .then(response => {
        assign('streets', response)
        commit('UPDATE_LOAD_REQUESTS')
        console.log('Streets Loaded')
        resolve(response)
      })
  }))
  promises.push(new Promise((resolve) => {
    get(['timezones'])
      .then(response => {
        assign('timezones', response)
        commit('UPDATE_LOAD_REQUESTS')
        console.log('Timezones Loaded')
        resolve(response)
      })
  }))

  return Promise.all(promises)
}

export const POLL_SERVER = ({ state, commit }) => {
  return new Promise((resolve, reject) => {
    const url = '/api/poll'
    axios.get(url)
      .then(function (response) {
        commit('UPDATE_REMOTE_CACHE', response.data.data.cache)
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const BACKGROUND_LOAD = ({ state, commit }) => {
  return new Promise((resolve, reject) => {
    //
  })
}

export const GET_ALL_CONGREGATIONS = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.get('api/world/congregations')
      .then(function (response) {
        commit('UPDATE_CONGREGATIONS', response.data.data)
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const GET_LANGUAGES = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.get('api/languages')
      .then(function (response) {
        commit('UPDATE_LANGS', response.data.data)
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}
