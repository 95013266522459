<template>
  <div ref="onlineMeetingPrompt" class="notification public-talk" :class="collapse ? 'collapsing' : ''" v-if="on">
    <button class="delete" @click="dismissAlert('onlineMeetingActive')"></button>
    <strong v-if="publicMeeting">{{ $t('notifications.meeting_conference.public') }}</strong>
    <strong v-else>{{ $t('notifications.meeting_conference.public') }}</strong>
    <br>
    <div>{{ $t('notifications.meeting_conference.message') }}<br>
      <span>{{ url }}</span>
    </div>
    <div class="mt-3 display-flex justify-content-end" style="margin-right:-25px">
      <a :href="url" target="_blank" class="button btn-sm btn-success">{{ $t('labels.button.join') }}</a>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, onMounted } from 'vue'
import { store } from '@/store'
import { Sec } from '@/mixins/sec'

const collapse = ref(false)
const height = ref(null)
const onlineMeetingPrompt = ref(null)

onMounted(() => {
  height.value = onlineMeetingPrompt.value ? onlineMeetingPrompt.value.clientHeight : 0
})

const cong = computed(() => store.state.settings.cong)
const onlineMeetingActive = computed(() => store.state.dashboard.onlineMeetingActive)
const publicMeeting = computed(() => {
  if (cong.value.modules && cong.value.modules.meeting_schedule) {
    if ((new Sec()).dayOfWeek() === parseInt(cong.value.modules.meeting_schedule.public_day)) return true
  }
  return false
})
const clam = computed(() => {
  if (cong.value.modules && cong.value.modules.meeting_schedule) {
    if ((new Sec()).dayOfWeek() === parseInt(cong.value.modules.meeting_schedule.clam_day)) return true
  }
  return false
})
const url = computed(() => {
  if (publicMeeting.value) return cong.value.modules.meeting_schedule.public_url
  if (clam.value) return cong.value.modules.meeting_schedule.clam_url
  return ''
})
const on = computed(() => {
  return onlineMeetingActive.value && cong.value.modules && cong.value.modules.meeting_schedule && cong.value.modules.meeting_schedule.on && (publicMeeting.value || clam.value)
})

const dismissAlert = (alert) => {
  collapse.value = true
  onlineMeetingPrompt.value.style.height = height.value + 'px'
  setTimeout(() => {
    onlineMeetingPrompt.value.style.height = 0
    onlineMeetingPrompt.value.style.padding = 0
    onlineMeetingPrompt.value.style.margin = 0
    setTimeout(() => {
      collapse.value = false
      store.dispatch('HIDE_ALERT', { alert: alert })
    }, 300)
  }, 500)
}

</script>
