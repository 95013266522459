<template>
    <div class="flex items-center" :class="!first ? 'flex-grow' : ''">
      <div v-if="!first" class="flex-grow w-full bg-gray-200 h-0.5" :class="bgColor"></div>
      <div class="flex flex-grow-0 items-center justify-center w-5 h-5 rounded-full flex-shrink-0"
           :class="bgColor"
      >
        <fa-icon :icon="['fal','check']" class="text-white" />
      </div>
    </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  state: String,
  first: {
    type: Boolean,
    default: false
  }
})

const bgColor = computed(() => {
  const classes = []
  if (props.state === 'success') classes.push('bg-teal-400')
  else if (props.state === 'failed') classes.push('bg-red-600')
  else classes.push('bg-gray-200')
  return classes.join(' ')
})
</script>
