var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_setup.alerts.group && _setup.alerts.group.location && _setup.nextGroupActive)?_c('div',{ref:"fieldGroupPrompt",staticClass:"notification is-group",class:_setup.collapse ? 'collapsing' : ''},[_c('button',{staticClass:"delete",on:{"click":function($event){return _setup.dismissAlert('nextGroupActive')}}}),(!_setup.alerts.group.online)?_c('div',{domProps:{"innerHTML":_vm._s(
          _vm.$t('notifications.next_group', {
              time: _setup.timeDisplay(_setup.time, _setup.timeFormat),
              label: _setup.alerts.group.label,
              location: _setup.alerts.group.location
          }))}}):_vm._e(),(_setup.alerts.group.online)?_c('div',{domProps:{"innerHTML":_vm._s(
          _vm.$t('notifications.next_group_online', {
              time: _setup.timeDisplay(_setup.time, _setup.timeFormat),
              label: _setup.alerts.group.label,
              url: _setup.alerts.group.location
          }))}}):_vm._e(),_c('div',{staticClass:"mt-3 display-flex justify-content-end",staticStyle:{"margin-right":"-25px"}},[_c('a',{staticClass:"button btn-sm btn-success",attrs:{"href":_setup.alerts.group.location,"target":"_blank"}},[_vm._v(_vm._s(_vm.$t('labels.button.join')))])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }