<template>
  <div class="modal font-sans z-50" :class="{ 'is-active': active }">
    <div class="fixed inset-0 bg-dark opacity-75"></div>
    <div class="modal-content modal-top min-h-full overflow-x-hidden">
      <div class="card">
        <header class="card-header">
          <p class="card-header-title">
            {{ header }}
          </p>
        </header>
        <div class="card-content">
          <div class="content">
            <slot name="message"></slot>
            <form v-if="!loading && active">
              <slot v-bind:error="errors"></slot>
            </form>
            <div class="spinner" v-if="loading">
              <fa-icon :icon="['fal', 'spinner-third']" spin />
              <p>Loading&hellip;</p>
            </div>
          </div>
        </div>
        <footer class="card-footer bg-danger text-light" v-if="error">
          <p>{{ error.message }}</p>
        </footer>
        <footer class="card-footer bg-red-500 text-light" v-if="axiosError">
          <p v-if="axiosError.message === 'The given data was invalid.'">{{ $t('validation.message') }}</p>
          <p v-else>{{ axiosError.message }}</p>
        </footer>
        <footer class="card-footer flex justify-between">
          <action :default="true" @click="close">
            {{ cancel || $t('actions.cancel') }}
          </action>
          <action :secondary="true" @click="submit" :disabled="disabled" @keydown.enter="submit">
            <fa-icon :icon="['fal', 'spinner-third']" spin v-if="submitting || api.submitting" />
            <fa-icon :icon="['fal', icon]" v-if="!submitting && !api.submitting && icon" />
            <span v-if="!submitting && !api.submitting">{{ save || $t('actions.save') }}</span>
          </action>
        </footer>
      </div>
      <slot name="preview"></slot>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted } from 'vue'
import { store } from '@/store'
import { useAPIStore } from '@/stores/api'

const props = defineProps(['active', 'header', 'validated', 'submitting', 'loading', 'save', 'cancel', 'icon', 'error'])
const emit = defineEmits(['update:active', 'close', 'submit'])

const api = useAPIStore()
const axiosError = computed(() => store.state.core.axiosError)

const errors = computed(() => {
  if (axiosError.value) return axiosError.value.errors
  return null
})

const disabled = computed(() => {
  if (props.validated === false) return true
  if (props.submitting) return true
  if (api.submitting) return true
  if (props.validated) return false
  return false
})

onMounted(() => {
  store.commit('axiosError', null)
})

const close = () => {
  store.commit('axiosError', null)
  emit('update:active', false)
  emit('close')
}

const submit = () => {
  if (!props.disabled) emit('submit')
}

</script>

<style lang="less">

.spinner {
  font-size: 30px;
  width: 100%;
  text-align: center;

  p {
    font-size: 12px;
  }
}

</style>
