<template>
  <div class="clam relative inset-x-0 top-0" v-if="startTime" :class="templateClass">

    <reset-schedule :meeting="meeting" />

    <meeting-part v-if="chairmanPart" :muted="canceled" :part="chairmanPart" :currentTime="currentTime" :hall="1" :chairman="true" @assign="assign" />
    <meeting-part v-if="chairmanPart && activeHalls > 1" :muted="canceled" :currentTime="currentTime" :part="chairmanPart" :hall="2" :chairman="true" @assign="assign" />
    <meeting-part v-if="chairmanPart && activeHalls > 2" :muted="canceled" :currentTime="currentTime" :part="chairmanPart" :hall="3" :chairman="true" @assign="assign" />

    <meeting-part v-for="part in preludeParts" :key="'part' + part.id" :muted="canceled" :currentTime="currentTime" :part="part" :edit="edit" @assign="assign" />

    <div class="treasures" v-if="treasureParts.length">
      <div class="heading mb-3 shadow-md" :class="canceled ? 'bg-gray-300 text-gray-600' : ''">
        {{ $t('schedules.clm.treasures') }} <span class="clock">{{ treasureStart | time }}</span>
      </div>
      <meeting-part v-for="part in treasureParts" :key="'part' + part.id" :muted="canceled" :currentTime="currentTime" :part="part" :edit="edit" :halls="activeHalls" @assign="assign" />
    </div>

    <div class="ministry" v-if="ministryParts.length">
      <div class="heading mb-3 shadow-md" :class="canceled ? 'bg-gray-300 text-gray-600' : ''">
        {{ $t('schedules.clm.ministry') }} <span class="clock">{{ ministryStart | time }}</span>
      </div>
      <meeting-part v-for="part in ministryParts" :key="'part' + part.id" :muted="canceled" :currentTime="currentTime" :part="part" :edit="edit" :halls="activeHalls" @assign="assign" />
    </div>

    <div class="living" v-if="livingParts.length">
      <div class="heading mb-3 shadow-md" :class="canceled ? 'bg-gray-300 text-gray-600' : ''">
        {{ $t('schedules.clm.living') }} <span class="clock">{{ livingStart | time }}</span>
      </div>
      <meeting-part v-for="part in livingParts" :key="'part' + part.id" :muted="canceled" :currentTime="currentTime" :part="part" :edit="edit" @assign="assign" />
    </div>

    <attendance-form v-if="cong.modules.meeting_attendance.on && profile.can('settings.meetings.attendance')" :attendance="attendance" :meeting="meeting" />

    <meeting-assignment
      :part="part"
      :hall="assignmentHall"
      :assignment="assignment"
      :assignmentType="assignmentType"
      :active.sync="assignmentFormActive" />

  </div>
</template>

<script setup>
import { computed, ref, watch, onMounted, onUnmounted } from 'vue'
import { store } from '@/store'
import { useCollectionsStore } from '@/stores/collections'
import { useProfileStore } from '@/stores/profile'
import { filter, find, orderBy, uniq } from 'lodash'
import { Sec } from '@/mixins/sec'
import ResetSchedule from './ResetSchedule'
import { useMeetingsStore } from '@/stores/meetings'

const props = defineProps(['activeMeeting', 'canceled', 'edit', 'halls', 'meeting', 'day'])

const newStore = useCollectionsStore()
const meetings = useMeetingsStore()
const profile = useProfileStore()
const loadDirection = ref('forward')
const part = ref({})
const assignmentHall = ref(1)
const assignment = ref({})
const assignmentType = ref('main')
const assignmentFormActive = ref(false)
const timerInterval = ref(null)
const currentTime = ref(null)

onMounted(() => {
  currentTime.value = new Sec().time
  timerInterval.value = setInterval(() => {
    currentTime.value = new Sec().time
  }, 1000)
})

onUnmounted(() => {
  if (timerInterval.value) clearInterval(timerInterval.value)
})

const cong = computed(() => store.state.settings.cong)
const attendance = computed(() => {
  const a = newStore.find('meetingAttendance', props.meeting.id, 'meeting_id')
  return a?.attendance || 0
})

const templateClass = computed(() => {
  if (props.activeMeeting === 'public') return 'animate-backward-out'
  else if (startTime.value) return 'animate-' + loadDirection.value + '-in'
  else return 'hidden'
})

const parts = computed(() => {
  return orderBy(filter(meetings.meetingPartsForMonth, p => p.meeting_id === props.meeting.edited_id ?? props.meeting.id), ['order', 'asc'])
})

const meetingAssignments = computed(() => {
  return filter(meetings.meetingAssignmentsForMonth, a => a.meeting_id === props.meeting.edited_id ?? props.meeting.id)
})

const activeHalls = computed(() => {
  const halls = []
  if (chairmanPart.value) {
    const assignments = filter(meetingAssignments.value, a => a.meeting_part_id === chairmanPart.value.id)
    assignments.forEach(a => {
      if (a.user_id || a.helper_id) halls.push(a.hall)
    })
  }
  if (treasureParts.value) {
    treasureParts.value.forEach(p => {
      const assignments = filter(meetingAssignments.value, a => a.meeting_part_id === p.id)
      if (assignments) {
        assignments.forEach(a => {
          if (a.user_id || a.helper_id) halls.push(a.hall)
        })
      }
    })
  }
  if (ministryParts.value) {
    ministryParts.value.forEach(p => {
      const assignments = filter(meetingAssignments.value, a => a.meeting_part_id === p.id)
      if (assignments) {
        assignments.forEach(a => {
          if (a.user_id || a.helper_id) halls.push(a.hall)
        })
      }
    })
  }
  const length = uniq(halls).length
  if (props.halls > 1) return Math.min(length + 1, 3)
  else return length
})

const startTime = computed(() => {
  const date = new Date(props.meeting?.start_date)
  const currentDay = date.getDay()
  const distance = parseInt(cong.value.modules.meeting_schedule.clam_day) - currentDay
  date.setDate(date.getDate() + distance)
  const time = cong.value.modules.meeting_schedule.clam_start_time.split(':')
  date.setHours(time[0], time[1], 0)
  return date
})

const treasureStart = computed(() => {
  let minutes = 0
  preludeParts.value.forEach(p => { minutes += parseInt(p.time) })
  return new Date(startTime.value.getTime() + (minutes * 60000))
})

const ministryStart = computed(() => {
  let minutes = 0
  treasureParts.value.forEach(p => { minutes += parseInt(p.time) })
  minutes += 1
  return new Date(treasureStart.value.getTime() + (minutes * 60000))
})

const livingStart = computed(() => {
  let minutes = 0
  ministryParts.value.forEach(p => {
    minutes += parseInt(p.time) + (partHasStudent(p) ? 1 : 0)
  })
  return new Date(ministryStart.value.getTime() + (minutes * 60000))
})

const chairmanPart = computed(() => {
  return find(parts.value, p => { return p.meeting === 'prelude' && p.part === 'comments' })
})

const preludeParts = computed(() => {
  const arr = filter(parts.value, p => p.meeting === 'prelude')
  let minutes = 0
  arr.forEach(p => {
    p.startTime = new Date(startTime.value.getTime() + (minutes * 60000))
    minutes += parseInt(p.time)
  })
  return arr
})

const treasureParts = computed(() => {
  const arr = filter(parts.value, p => p.meeting === 'treasures')
  let minutes = 0
  arr.forEach(p => {
    p.startTime = new Date(treasureStart.value.getTime() + (minutes * 60000))
    minutes += parseInt(p.time)
  })
  return arr
})

const ministryParts = computed(() => {
  const arr = filter(parts.value, p => p.meeting === 'ministry')
  let minutes = 0
  arr.forEach(p => {
    p.startTime = new Date(ministryStart.value.getTime() + (minutes * 60000))
    minutes += parseInt(p.time)
    if (partHasStudent(p)) minutes += 1
  })
  return arr
})

const livingParts = computed(() => {
  const arr = filter(parts.value, p => p.meeting === 'living')
  let minutes = 0
  arr.forEach(p => {
    p.startTime = new Date(livingStart.value.getTime() + (minutes * 60000))
    minutes += parseInt(p.time)
  })
  return arr
})

watch(() => props.day, (newVal, oldVal) => {
  if (props.activeMeeting === 'clam') {
    if (parseInt(newVal) > parseInt(oldVal)) loadDirection.value = 'backward'
    else loadDirection.value = 'forward'
  }
})

watch(() => props.activeMeeting, (newVal, oldVal) => {
  if (oldVal === 'public') loadDirection.value = 'forward'
  else loadDirection.value = 'backward'
})

const assign = (p, hall, a, type) => {
  part.value = p
  assignment.value = a
  assignmentType.value = type || 'main'
  assignmentHall.value = hall || 1
  assignmentFormActive.value = true
}
const partHasStudent = (part) => {
  if (part.meeting === 'ministry') {
    if (part.title.includes('Video')) return false
    if (part.title.includes('Apply Yourself')) return false
    if (part.title.includes('Talk')) return false
    if (part.title.includes('Discussion')) return false
    if (part.references && part.references.includes('Discussion')) return false
    return true
  } else if (part.meeting === 'treasures' && part.part === 'reading') return true
  return false
}

</script>
