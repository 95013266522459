<template>
  <div>
    <modal :header="$t('labels.congregation.meeting_settings')" :active="active" @submit="submit" @close="emit('close')">
      <template v-slot:default="{ error }">
      <div v-if="!congregation.active">
        <input-text :name="'name'" :label="'Name'" v-model="resource.name" :error="error" />
      </div>
      <div v-if="!congregation.active || cong.id === congregation.id">
        <tuxedo-select
          :inputClass="'bg-gray-100'"
          :label="$t('fields.country.label')"
          :name="'country_id'"
          :options="countries"
          v-model="resource.country_id"
          :insistReturn="'id'"
          :insistLabel="'name'"
          :error="error"
        />
        <tuxedo-select
          :inputClass="'bg-gray-100'"
          :label="$t('fields.region.label')"
          :name="'region_id'"
          v-model="resource.region_id"
          :options="filteredRegions"
          :insistLabel="'name'"
          :insistReturn="'id'"
          :error="error"
        />
        <input-text :name="'suburb'" :label="'Suburb'" v-model="resource.suburb" :error="error" />
        <input-text :name="'post_code'" :label="'Zip/Post Code'" v-model="resource.postcode" :error="error" />
        <input-text :name="'address'" :label="'Address'" v-model="resource.address" :error="error" />
        <input-text :name="'circuit'" :label="'Circuit'" v-model="resource.circuit" :error="error" />
        <tuxedo-select
          :label="$t('fields.language.label')"
          name="lang"
          :options="langs"
          v-model="resource.lang"
          insistLabel="lang"
          inputClass="bg-gray-100"
          insistReturn="iso"
        />
        <tuxedo-select
          :label="'Timezone'"
          name="timezone_id"
          :options="filteredTimezones"
          v-model="resource.timezone_id"
          insistLabel="name"
          inputClass="bg-gray-100"
          insistReturn="id"
        />
      </div>
      <input-slider :name="'public_day'" :label="$t('fields.public_meeting_day.label')" v-model="resource.public_day" :options="days" :error="error" />
      <input-time :name="'public_start_time'" class="pt-3" :label="$t('fields.public_meeting_start_time.label')" :minute-step="5" v-model="resource.public_start_time" :error="error" />
      </template>
    </modal>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import { useAPIStore } from '@/stores/api'
import { useCollectionsStore } from '@/stores/collections'
import { store } from '@/store.js'
import { filter } from 'lodash'

const props = defineProps(['congregation', 'active'])
const emit = defineEmits(['close'])

const api = useAPIStore()
const newStore = useCollectionsStore()

const cong = store.state.settings.cong
const timezones = computed(() => store.state.timezones)
const langs = computed(() => store.state.core.langs)
const countries = newStore.all('countries')
const regions = newStore.all('regions')

const initialResource = {
  id: null,
  name: null,
  country_id: null,
  region_id: null,
  suburb: null,
  postcode: null,
  address: null,
  circuit: null,
  lang: null,
  timezone_id: null,
  ptc: null,
  public_day: null,
  public_start_time: null
}

const resource = ref({ ...initialResource })

const days = ref({
  0: 'Sun',
  1: 'Mon',
  2: 'Tue',
  3: 'Wed',
  4: 'Thu',
  5: 'Fri',
  6: 'Sat'
})

watch(() => props.active, async (active) => {
  if (active === true) {
    resource.value = { ...initialResource }
    Object.keys(resource.value).forEach(key => {
      if (props.congregation[key]) resource.value[key] = props.congregation[key]
    })
  }
})

const filteredRegions = computed(() => {
  if (resource.value.country_id) {
    return filter(regions, r => r.country_id === parseInt(resource.value.country_id))
  }
  return regions
})

const filteredTimezones = computed(() => {
  if (resource.value.country_id && timezones.value) {
    return filter(timezones.value, z => z.countries.includes(parseInt(resource.value.country_id)))
  }
  return timezones.value
})

const submit = () => {
  resource.value.congregation = props.congregation.id
  api.post(['congregation', props.congregation.id], resource.value).then(res => {
    emit('saveResponse', res)
  })
}

</script>
