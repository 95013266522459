<template>

  <section class="assignment" :id="meeting.start_date" :class="meeting.event ? 'assembly' : 'meeting'">

    <TabbedCard :color="event ? 'event' : 'meeting'" class="mb-5">
      <template v-slot:tab>
        <div class="flex flex-col leading-none pl-1">
          <small>{{ meeting.local_date | shortMonth }}</small>
          <strong class="text-white">{{ meeting.local_date | jS }}</strong>
        </div>
      </template>
      <div class="flex justify-between">
        <BaseHeading>
          <span v-if="event">
            <span v-if="event.type === 'custom'">{{ event.label }}</span>
            <span v-else>{{ $t('labels.appointments.' + event.type) }}</span>
          </span>
          <span v-else>Public Talk</span>
        </BaseHeading>
        <ContextButton v-if="!eventHasSpeaker" @click="pmActions = meeting.id" :id="'pmMenu' + meeting.id" />
      </div>
      <BaseContent>
        <span v-if="event && event.has_speaker" class="font-light">
          <fa-icon :icon="['fal','user-tie']" class="fa mr-2" />
          <span>{{ event.speaker }}</span><br>
          <fa-icon :icon="['fal','podium']" class="fa mr-2" /><span v-html="event.theme"></span><br>
        </span>
        <span v-if="!event || publicTalk" class="font-light">
          <fa-icon :icon="['fal','user-tie']" class="fa mr-2" />
          <span v-if="publicTalk">{{ publicTalk.speaker_name }} ({{ publicTalk.speaker_congregation }} Congregation)</span><br>
          <fa-icon :icon="['fal','podium']" class="fa mr-2" /><span v-html="publicTalk ? publicTalk.notes : ''"></span>
        </span>
      </BaseContent>

      <div class="text-left bg-gray-50 p-2 -mx-2 -mb-2 mt-2 border-t border-gray-200" v-if="!eventHasSpeaker">
        <div v-if="!meetingCanceled">
          <ProgressBar>
            <ProgressIndicator :state="publicTalk ? 'success' : ''" :first="true" />
            <ProgressIndicator :state="publicTalk ? 'success' : ''" />
            <ProgressIndicator :state="publicTalk ? 'success' : ''" />
            <ProgressIndicator :state="'success'" v-if="publicTalk && parseInt(publicTalk.confirmed) === 1" />
            <ProgressIndicator :state="'failed'" v-else-if="publicTalk && parseInt(publicTalk.confirmed) === 2" />
            <ProgressIndicator :state="''" v-else />
          </ProgressBar>
          <SuccessBadge v-if="publicTalk && parseInt(publicTalk.confirmed) === 1" class="mt-1.5">
            {{ $t('labels.states.confirmed') }}
          </SuccessBadge>
          <WarningBadge v-else-if="publicTalk && parseInt(publicTalk.confirmed) === 0" class="mt-1.5">
            {{ $t('labels.states.awaiting_confirmation') }}
          </WarningBadge>
          <ErrorBadge v-else-if="publicTalk && parseInt(publicTalk.confirmed) === 2" class="mt-1.5">
            {{ $t('labels.states.speaking_invitation_declined') }}
          </ErrorBadge>
        </div>
        <SuccessBadge v-else>
          {{ $t('labels.states.no_meeting') }}
        </SuccessBadge>
      </div>
    </TabbedCard>

    <secondary-menu v-if="pmActions" @close="pmActions = false" :target="'pmMenu' + pmActions">
      <ul class="text-lg font-sans">
        <sm-item class="px-4" :icon="['fal', 'arrows-alt-v']" v-if="publicTalk" @click="pmActions = false;$emit('moveSpeaker', meeting)">
            <span>
              {{ $t('actions.action_resource', { action: $t('actions.move'), resource: $tc('resources.booking_date', 1) }) }}
            </span>
        </sm-item>
        <sm-item class="px-4 text-teal-400" :icon="['fad', 'calendar-plus']" v-if="!publicTalk" @click="book">
          <span>
            {{ $t('actions.action_resource', { action: $t('actions.book'), resource: $tc('resources.public_speaker', 1) }) }}
          </span>
        </sm-item>
        <sm-item class="px-4" v-if="publicTalk" :icon="['fal', 'podium']" @click="edit">
          <span>
            {{ $t('actions.action_resource', { action: $t('actions.change'), resource: $tc('resources.public_talk_outline', 1) }) }}
          </span>
        </sm-item>
        <sm-item class="px-4 text-teal" v-if="publicTalk && parseInt(publicTalk.confirmed) === 0" :icon="['fal', 'check']" @click="confirmSpeakerAssignment">
          <span>
            {{ $t('actions.action_resource', { action: $t('actions.confirm'), resource: $tc('resources.public_speaker_booking', 1) }) }}
          </span>
        </sm-item>
        <sm-item v-if="publicTalk" @click="promptClearMeeting" :icon="['fal', 'trash']" class="px-4 text-red-700 border-t mt-2">
          {{ $t('actions.action_resource', { action: $t('actions.clear'), resource: $tc('resources.public_speaker_booking', 1) }) }}
        </sm-item>
      </ul>
    </secondary-menu>

    <portal to="modals" v-if="changeMeetingFormActive || clearMeetingActive">

      <delete
        :submitting="submitting"
        :header="'Clear Public Talk'"
        :active.sync="clearMeetingActive"
        :message="'Are you sure you wish to clear this public talk?'"
        @submit="confirmClearMeeting()"
      ></delete>

    </portal>

  </section>

</template>

<script setup>
import { ref, computed } from 'vue'
import { store } from '@/store.js'
import { post } from '@/composables/serverInterface'
import { merge } from '@/composables/dataInterface'
import TabbedCard from '@/components/elements/TabbedCard.vue'
import BaseHeading from '@/components/elements/BaseHeading.vue'
import BaseContent from '@/components/elements/BaseContent.vue'
import ContextButton from '@/components/elements/ContextButton.vue'
import ProgressBar from '@/components/elements/ProgressBar.vue'
import ProgressIndicator from '@/components/elements/ProgressIndicator.vue'
import SuccessBadge from '@/components/elements/SuccessBadge.vue'
import WarningBadge from '@/components/elements/WarningBadge.vue'
import ErrorBadge from '@/components/elements/ErrorBadge.vue'

const props = defineProps(['meeting'])
const emits = defineEmits(['search', 'edit'])

const changeMeetingFormActive = ref(false)
const clearMeetingActive = ref(false)
const submitting = ref(false)
const pmActions = ref(null)

const allEvents = store.state.events

const publicTalk = computed(() => {
  let talk = null
  if (props.meeting && props.meeting.parts) {
    talk = props.meeting.parts.find(p => {
      return p.meeting === 'talk' && p.part === 'talk'
    })
  }
  if (talk && talk.assignments && talk.assignments.length > 0) {
    return talk.assignments[0]
  }
  return null
})

const event = computed(() => {
  return allEvents.find(r => {
    const week = new Date(r.week_date)
    if (props.meeting) {
      const start = new Date(props.meeting.start_date)
      return week.getFullYear() === start.getFullYear() &&
        week.getMonth() === start.getMonth() &&
        week.getDate() === start.getDate() &&
        (r.has_speaker || r.cancels_meeting === 'both' || r.cancels_meeting === 'public')
    }
  })
})

const meetingCanceled = computed(() => {
  return event.value && (event.value.cancels_meeting === 'public' || event.value.cancels_meeting === 'both')
})

const eventHasSpeaker = computed(() => {
  return event.value && event.value.has_speaker
})

const promptClearMeeting = () => {
  pmActions.value = null
  clearMeetingActive.value = true
}

const confirmClearMeeting = () => {
  submitting.value = true
  post(['clear-speaker-assignment'], publicTalk.value)
    .then(res => {
      merge('publicMeetings', res)
      submitting.value = false
      clearMeetingActive.value = false
    })
}

const confirmSpeakerAssignment = () => {
  pmActions.value = null
  post(['confirm-speaker-assignment'], publicTalk.value)
    .then(res => {
      merge('publicMeetings', res)
      clearMeetingActive.value = false
    })
}

const edit = () => {
  pmActions.value = null
  emits('edit', publicTalk.value)
}

const book = () => {
  pmActions.value = null
  emits('search')
}

</script>
