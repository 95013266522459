<template>
  <div>
    <div v-if="event && display" class="rounded shadow mb-3 bg-gradient-to-r from-yellow-500 to-yellow-300 p-2 text-left relative overflow-hidden">
      <fa-icon :icon="['fas', 'star']" class="absolute -right-10 -top-2 text-9xl text-yellow-100" />
      <div class="relative z-10">
        <div class="uppercase text-xs text-white font-normal">
          <span v-if="event.multi_day">{{ start | ljS }} &mdash; {{ end | ljS }}</span>
          <span v-else>{{ start | ljS }}</span>
        </div>
        <div class="font-sans text-base font-bold text-white text-lg leading-4">
          <span v-if="event.type === 'custom'">{{ event.label }}</span>
          <span v-else>{{ $t('labels.appointments.' + event.type) }}</span>
        </div>
        <div class="font-sans font-medium mt-2">
          <div v-if="event.has_speaker">{{ $t('labels.speaker', { speaker: event.speaker }) }}</div>
          <div v-if="event.type === 'assembly'">
            {{ $t('labels.theme', { theme: event.label }) }}
          </div>
          <div v-else-if="event.theme">{{ $t('labels.theme', { theme: event.theme }) }}</div>
        </div>
        <div class="mt-2 font-sans font-medium text-xs">
          <span v-if="event.cancels_meeting == 'public'">*{{ $t('messages.canceled_public_meeting') }}</span>
          <span v-if="event.cancels_meeting == 'clm'">*{{ $t('messages.canceled_clm_meeting') }}</span>
          <span v-if="event.cancels_meeting == 'both'">*{{ $t('messages.canceled_both_meeting') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ScheduleEvents',
  props: ['events'],
  computed: {
    ...mapState({
      profile: state => state.profile
    }),
    event () {
      if (this.assemblyEvent) return this.assemblyEvent
      if (this.coEvent) return this.coEvent
      if (this.events[0]) return this.events[0]
      return false
    },
    coEvent () {
      return this._.find(this.events, e => {
        return e.type === 'co'
      })
    },
    assemblyEvent () {
      return this._.find(this.events, e => {
        return e.type === 'assembly'
      })
    },
    start () {
      if (this.event.base_date) return new Date(this.event.base_date)
      return null
    },
    end () {
      if (this.event.end_date) return new Date(this.event.end_date)
      return null
    },
    display () {
      if (this.event.extent === 'everyone') return true
      if (this.event.extent === 'elders' && this.profile.appointment === 'elder') return true
      if (this.event.extent === 'appointed' && (this.profile.appointment === 'servant' || this.profile.appointment === 'elder')) return true
      if (this.event.extent === 'pioneers_elders' && (this.profile.appointment === 'elder' || this.profile.pioneer)) return true
      if (this.event.extent === 'pioneers' && this.profile.pioneer) return true
      if (!this.event.extent) return true
      return false
    }
  }
}
</script>
