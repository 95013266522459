<template>
  <div ref="cartPrompt" class="notification is-cart" :class="collapse ? 'collapsing' : ''"
       v-if="alerts.cart && nextCartActive && location"
  >
    <button class="delete" @click="dismissAlert('nextCartActive')"></button>
    <div v-html="
            $t('notifications.next_cart', {
                location: location.location,
                date: dayDateMonthDisplay(time),
                time: timeDisplay(time, timeFormat)
            })"></div>
  </div>
</template>

<script setup>
import { computed, ref, onMounted } from 'vue'
import { store } from '@/store'
import { Sec } from '@/mixins/sec'
import { dayDateMonthDisplay, timeDisplay } from '@/helpers'

const collapse = ref(false)
const height = ref(null)
const cartPrompt = ref(null)

onMounted(() => {
  height.value = cartPrompt.value ? cartPrompt.value.clientHeight : 0
})

const alerts = computed(() => store.state.dashboard.alerts)
const nextCartActive = computed(() => store.state.dashboard.nextCartActive)
const cartLocations = computed(() => store.state.cartLocations)
const timeFormat = computed(() => store.state.settings.cong.time_format)
const location = computed(() => {
  return cartLocations.value.find(l => parseInt(l.id) === parseInt(alerts.value.cart.cart_location_id))
})
const time = computed(() => {
  return new Sec(alerts.value.cart.start_at).time
})

const dismissAlert = (alert) => {
  collapse.value = true
  cartPrompt.value.style.height = height.value + 'px'
  setTimeout(() => {
    cartPrompt.value.style.height = 0
    cartPrompt.value.style.padding = 0
    cartPrompt.value.style.margin = 0
    setTimeout(() => {
      collapse.value = false
      store.dispatch('HIDE_ALERT', { alert: alert })
    }, 300)
  }, 500)
}

</script>
