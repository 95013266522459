import Vue from 'vue'

import { library } from '@fortawesome/fontawesome-svg-core'

import {
  faCalendarAlt, faSpinnerThird, faMap, faListAlt, faCog, faTrash, faPencil, faCalendar,
  faClock, faUsers, faMapMarkerAlt, faPodium, faThumbsUp, faThumbsDown, faBan, faDoorOpen, faCheck, faInboxIn, faPlus,
  faExclamation, faPaperPlane, faMobile, faExclamationCircle, faUpload, faInboxOut, faArrowAltDown,
  faFilter, faClipboardList, faArrowAltUp, faCalendarTimes, faUserTie, faSms, faEnvelope, faBookOpen,
  faLockAlt, faFileInvoice, faMoneyCheckEditAlt, faCloudDownload, faMinus, faSort, faLock, faUnlock, faSignalStream,
  faPrint, faInfo, faClipboardUser, faPhoneOffice, faPhoneSlash, faBell, faBooks, faCrosshairs, faPhonePlus, faReceipt,
  faUser, faEllipsisH, faSearch, faDoorClosed, faUserEdit, faHandshake, faArrowsAltV, faExchangeAlt
} from '@fortawesome/pro-light-svg-icons'

import {
  faLevelDown, faChevronLeft, faChevronRight, faPlay, faPause, faEllipsisV, faTimes, faMale, faFemale, faStar
} from '@fortawesome/pro-solid-svg-icons'

import {
  faLightbulbOn, faHandsHeart, faComments, faMap as faMapd, faListAlt as faListAltd, faCalendarAlt as faCalendarAltd,
  faCog as faCogd, faCalendarPlus, faBolt
} from '@fortawesome/pro-duotone-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(
  faCalendarAlt, faSpinnerThird, faMap, faListAlt, faCog, faChevronLeft, faChevronRight, faTrash, faPencil, faCalendar,
  faClock, faUsers, faMapMarkerAlt, faPodium, faThumbsUp, faThumbsDown, faBan, faDoorOpen, faCheck, faInboxIn, faPlus,
  faCalendarPlus, faExclamation, faPaperPlane, faMobile, faExclamationCircle, faUpload, faInboxOut, faArrowAltDown,
  faFilter, faClipboardList, faArrowAltUp, faCalendarTimes, faUserTie, faStar, faSms, faEnvelope, faBookOpen,
  faLockAlt, faFileInvoice, faMoneyCheckEditAlt, faCloudDownload, faMinus, faSort, faLock, faUnlock, faSignalStream,
  faPrint, faInfo, faClipboardUser, faPhoneOffice, faPhoneSlash, faBell, faBooks, faCrosshairs, faPhonePlus, faReceipt,
  faLevelDown, faUser, faEllipsisH, faSearch, faLightbulbOn, faHandsHeart, faComments, faMapd, faListAltd,
  faCalendarAltd, faCogd, faPlay, faPause, faEllipsisV, faTimes, faMale, faFemale, faDoorClosed, faBolt, faUserEdit,
  faHandshake, faArrowsAltV, faExchangeAlt
)

Vue.component('fa-icon', FontAwesomeIcon)
